.add-top-cart-container{
    position: absolute;
    bottom: 35px;
}
.add-top-cart-container button.btn-primary-et{
    font-size: 14px;
}
.card-bottom-container{
    padding-bottom: 4em !important;
}
.add-top-cart-container .duty-free-quantity-container{
    font-size: 17px;
}