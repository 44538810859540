.confirmation-summary-item-container p {
  padding-top: 1rem;
}
.item-info-colon {
  display: none;
}
.confirmation-summary-item-container {
  border: 1px solid #eee;
  box-shadow: 0 5px 10px #eee;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 1rem;
}
.summary-head-txt {
  color: rgb(46, 125, 50);
  text-align: center;
  border: 1px solid #ddd;
  padding: 18px;
  margin: 37px 0;
  /* border-radius: 10px; */
  background: mintcream;
  /* color: white !important; */
  background: rgb(233 236 239);
  border-radius: 4px;
}
@media (max-width: 991px) {
  .confirmation-summary-item-container p {
    padding-top: 0;
    padding-left: 1rem;
  }
  .item-info {
    display: flex;
  }
  .item-info-colon {
    display: inline;
  }
}
