@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@font-face {
    font-family: 'SebastianFont';
    src: url('../public/fonts/Sebastian/Sebastian.ttf') format('truetype');
    font-display: swap
}

body {
    /* font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
    font-family: Roboto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'SebastianFont', sans-serif;
}

:root {
    --footer-background-color: #e9ecef;
    --main-background-color: #f1f1f1;
}

.form-select:focus {
    border-color: green;
    box-shadow: 0 5px 10px #ddd;
}

.accordion-button:focus {
    border-color: green;
    box-shadow: 0 5px 10px #ddd;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.light-background-color {
    background: var(--main-background-color);
}

/* cart related */
.shopping-cart-icon-container {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    align-items: center;
    justify-content: center;
}

.number-of-items-in-the-cart {
    color: black;
    font-size: 14px;
    margin-top: 2px;
}

.icon-on-header {
    width: 25px;
    height: 25px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.et-btn {
    background-color: #2e7d32;
    border-color: #2e7d32 !important;
}

    .et-btn:hover,
    .et-btn:active,
    .et-btn:focus,
    .et-btn:visited {
        /* border-color: #005005 !important; */
        color: white !important;
        background-color: #005005 !important;
        border-radius: 6px !important;
        box-shadow: none !important;
    }

/* button style */
.duty-free-primary-btn {
    background-color: #2e7d32;
    border-color: #2e7d32 !important;
    border-radius: 6px !important;
    color: white;
    font-size: 16px !important;
    font-weight: 600 !important;
}

    .duty-free-primary-btn:hover,
    .duty-free-primary-btn:active,
    .duty-free-primary-btn:focus:visited {
        background-color: #005005;
        border-color: #005005 !important;
        border-radius: 6px !important;
        color: white;
        font-size: 16px !important;
        font-weight: 600 !important;
    }

.duty-free-secondary-btn {
    background-color: #fff;
    border-color: #d32f2f !important;
    border-radius: 6px !important;
    color: #d32f2f;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.duty-free-outline-primary-btn {
    background-color: #fff;
    border-radius: 6px !important;
    color: #005005;
    font-size: 16px !important;
    font-weight: 600 !important;
    border: 1px solid #005005 !important;
    padding-bottom: 3px;
}

.duty-free-outline-secondary-btn {
    background-color: white;
    border-radius: 6px !important;
    color: #d32f2f;
    font-size: 16px !important;
    font-weight: 600 !important;
    border: 1px solid #d32f2f !important;
}

.duty-free-primary-btn:hover {
    background-color: #005005;
}

.duty-free-primary-btn:focus,
.duty-free-primary-btn:active,
.duty-free-outline-primary-btn:focus,
.duty-free-outline-primary-btn:active {
    outline: 0;
    background-color: #005005 !important;
    box-shadow: 0 0 0px 0.14rem #2f7f3338 !important;
}

.duty-free-secondary-btn:hover {
    background-color: #9a0007;
    color: white;
}

.duty-free-secondary-btn:focus,
.duty-free-secondary-btn:active,
.duty-free-outline-secondary-btn:focus,
.duty-free-outline-secondary-btn:active {
    outline: 0;
    box-shadow: 0 0 0px 0.14rem #d32f2f38 !important;
}

.duty-free-outline-primary-btn:hover {
    /* border-color: #005005 !important; */
    color: white !important;
    background-color: #005005;
    border-radius: 6px !important;
}

.duty-free-outline-secondary-btn:hover {
    /* border-color: #9a0007 !important; */
    /* color: #9a0007 !important; */
    background-color: #9a0007;
    border-radius: 6px !important;
    color: white;
}

.duty-free-primary-btn.disabled,
.duty-free-primary-btn:disabled {
    color: #fff;
    background-color: #2e7d32d4;
    border-color: #2e7d32d4;
}

.duty-free-outline-primary-btn:disabled {
    background-color: #e8e8e8;
    border-radius: 6px !important;
    color: #6d6d6d;
    font-size: 16px !important;
    font-weight: 600 !important;
    border: 1px solid #6d6d6d !important;
}

/* button style end */

/* input style */
.duty-free-input-field-control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #dddddd;
    border-radius: 6px !important;
    box-shadow: 0 1px 3px #848e9640, 0 4px 8px 3px #70787d00;
    border-radius: 6px !important;
}

.duty-free-widget-input-with-icon {
    padding-top: 15px !important;
    height: 50px !important;
    font-size: 14px;
    width: 100% !important;
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
    padding-left: 3em;
}

.duty-free-input {
    padding-top: 39px !important;
    padding-bottom: 15px;
}

.duty-free-input-field-control:focus {
    border-color: #2e7d32;
    /* border: 1px solid #2e7d32; */
    -webkit-box-shadow: none;
    box-shadow: 0 1px 3px #3c40434d, 0 4px 8px 3px #3c404326;
    outline: 0;
}

.duty-free-input-field-control[disabled] {
    background: #0000000a;
}

.duty-free-input-field-control.invalid {
    border-color: red;
}

/* input field style end */

.duty-free-unselectable-text {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.dty-fr-input-field-validation-error {
    font-size: 14px;
    padding-left: 5px;
}

#log-in-using-sheba-miles {
    background-color: #fff !important;
    color: #2e7d32 !important;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {

    input[type="checkbox"],
    input[type="radio"] {
        --active: #2e7d32;
        --active-inner: #fff;
        --focus: 2px rgba(46, 125, 50, 0.3);
        --border: #abe2ae;
        --border-hover: #2e7d32;
        --background: #fff;
        --disabled: #f6f8ff;
        --disabled-inner: #e1e6f9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }

        input[type="checkbox"]:after,
        input[type="radio"]:after {
            content: "";
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
        }

        input[type="checkbox"]:checked,
        input[type="radio"]:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: 0.3s;
            --d-t: 0.6s;
            --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }

        input[type="checkbox"]:disabled,
        input[type="radio"]:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: 0.9;
        }

            input[type="checkbox"]:disabled:checked,
            input[type="radio"]:disabled:checked {
                --b: var(--disabled-inner);
                --bc: var(--border);
            }

            input[type="checkbox"]:disabled + label,
            input[type="radio"]:disabled + label {
                cursor: not-allowed;
            }

        input[type="checkbox"]:hover:not(:checked):not(:disabled),
        input[type="radio"]:hover:not(:checked):not(:disabled) {
            --bc: var(--border-hover);
        }

        input[type="checkbox"]:focus,
        input[type="radio"]:focus {
            box-shadow: 0 0 0 var(--focus);
        }

        input[type="checkbox"]:not(.switch),
        input[type="radio"]:not(.switch) {
            width: 21px;
        }

            input[type="checkbox"]:not(.switch):after,
            input[type="radio"]:not(.switch):after {
                opacity: var(--o, 0);
            }

            input[type="checkbox"]:not(.switch):checked,
            input[type="radio"]:not(.switch):checked {
                --o: 1;
            }

        input[type="checkbox"] + label,
        input[type="radio"] + label {
            font-size: 14px;
            line-height: 21px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 4px;
        }

        input[type="checkbox"]:not(.switch) {
            border-radius: 7px;
        }

            input[type="checkbox"]:not(.switch):after {
                width: 5px;
                height: 9px;
                border: 2px solid var(--active-inner);
                border-top: 0;
                border-left: 0;
                left: 7px;
                top: 4px;
                transform: rotate(var(--r, 20deg));
            }

            input[type="checkbox"]:not(.switch):checked {
                --r: 43deg;
            }

        input[type="checkbox"].switch {
            width: 38px;
            border-radius: 11px;
        }

            input[type="checkbox"].switch:after {
                left: 2px;
                top: 2px;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: var(--ab, var(--border));
                transform: translateX(var(--x, 0));
            }

            input[type="checkbox"].switch:checked {
                --ab: var(--active-inner);
                --x: 17px;
            }

            input[type="checkbox"].switch:disabled:not(:checked):after {
                opacity: 0.6;
            }

    input[type="radio"] {
        border-radius: 50%;
    }

        input[type="radio"]:after {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            background: var(--active-inner);
            opacity: 0;
            transform: scale(var(--s, 0.7));
        }

        input[type="radio"]:checked {
            --s: 0.5;
        }
}

@media (min-width: 768px) {
    .df-product-container .container {
        max-width: 916px;
    }
}

@media (max-width: 768px) {
    .df-product-container .container {
        max-width: 916px;
    }
}

@media (max-width: 428px) {
    #log-in-using-sheba-miles span {
        font-size: 13px;
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    #log-in-using-sheba-miles img {
        height: auto;
        width: 48px;
        margin-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
}

@media (max-width: 329px) {
    #log-in-using-sheba-miles img {
        display: none;
    }

    #log-in-using-sheba-miles span {
        font-size: 15px;
    }
}

@media (min-width: 992px) {
    .df-product-container .container {
        max-width: 980px;
    }
}

@media (min-width: 1200px) {
    .df-product-container .container {
        max-width: 1300px;
    }

    input[type="checkbox"],
    input[type="radio"] {
        --active: #2e7d32;
        --active-inner: #fff;
        --focus: 2px rgba(46, 125, 50, 0.3);
        --border: #abe2ae;
        --border-hover: #2e7d32;
        --background: #fff;
        --disabled: #f6f8ff;
        --disabled-inner: #e1e6f9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }

        input[type="checkbox"]:after,
        input[type="radio"]:after {
            content: "";
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
        }

        input[type="checkbox"]:checked,
        input[type="radio"]:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: 0.3s;
            --d-t: 0.6s;
            --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }

        input[type="checkbox"]:disabled,
        input[type="radio"]:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: 0.9;
        }

            input[type="checkbox"]:disabled:checked,
            input[type="radio"]:disabled:checked {
                --b: var(--disabled-inner);
                --bc: var(--border);
            }

            input[type="checkbox"]:disabled + label,
            input[type="radio"]:disabled + label {
                cursor: not-allowed;
            }

        input[type="checkbox"]:hover:not(:checked):not(:disabled),
        input[type="radio"]:hover:not(:checked):not(:disabled) {
            --bc: var(--border-hover);
        }

        input[type="checkbox"]:focus,
        input[type="radio"]:focus {
            box-shadow: 0 0 0 var(--focus);
        }

        input[type="checkbox"]:not(.switch),
        input[type="radio"]:not(.switch) {
            width: 21px;
        }

            input[type="checkbox"]:not(.switch):after,
            input[type="radio"]:not(.switch):after {
                opacity: var(--o, 0);
            }

            input[type="checkbox"]:not(.switch):checked,
            input[type="radio"]:not(.switch):checked {
                --o: 1;
            }

        input[type="checkbox"] + label,
        input[type="radio"] + label {
            font-size: 14px;
            line-height: 21px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 4px;
        }

        input[type="checkbox"]:not(.switch) {
            border-radius: 7px;
        }

            input[type="checkbox"]:not(.switch):after {
                width: 5px;
                height: 9px;
                border: 2px solid var(--active-inner);
                border-top: 0;
                border-left: 0;
                left: 7px;
                top: 4px;
                transform: rotate(var(--r, 20deg));
            }

            input[type="checkbox"]:not(.switch):checked {
                --r: 43deg;
            }

        input[type="checkbox"].switch {
            width: 38px;
            border-radius: 11px;
        }

            input[type="checkbox"].switch:after {
                left: 2px;
                top: 2px;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: var(--ab, var(--border));
                transform: translateX(var(--x, 0));
            }

            input[type="checkbox"].switch:checked {
                --ab: var(--active-inner);
                --x: 17px;
            }

            input[type="checkbox"].switch:disabled:not(:checked):after {
                opacity: 0.6;
            }

    input[type="radio"] {
        border-radius: 50%;
    }

        input[type="radio"]:after {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            background: var(--active-inner);
            opacity: 0;
            transform: scale(var(--s, 0.7));
        }

        input[type="radio"]:checked {
            --s: 0.5;
        }
}

.btn-feed-back {
    position: fixed;
    right: -37px;
    top: 52%;
    transform: rotate(270deg);
    z-index: 12;
    padding: 0.4em 1.6em;
}

#up-to-top {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    background-color: #2e7d32;
    border-radius: 100%;
    position: fixed;
    bottom: 70px;
    right: 25px;
    cursor: pointer;
    color: #fff;
    z-index: 13;
    box-shadow: 0 0 25px #2e7d3252;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
}

    #up-to-top span {
        max-width: 35px;
        transition: 0.3s ease-in-out;
    }

        #up-to-top span img {
            object-fit: cover;
            object-position: center;
            max-width: 100%;
        }

    #up-to-top:hover {
        box-shadow: 0 0 30px #005005d1;
        background-color: #005005;
    }

.active {
    visibility: visible !important;
    opacity: 1 !important;
}

.is-invalid-select > div,
.is-invalid-input,
div.is-invalid-input div.SingleDatePickerInput {
    border-color: rgb(147 0 0) !important;
}

.cursor-pointer {
    cursor: pointer;
}

.filter-label:hover {
    color: #2e7d32 !important;
}

/* from main website */
.btn-primary-et,
.btn-secondary-et,
.btn-outline-et,
.btn-outline-radius-et,
.btn-radius-et {
    border: 1px solid #fff;
    font-size: 16px;
    font-weight: 500;
}

.btn-primary-et {
    color: #fff;
    background-color: #005005;
}

.btn-submit-btn {
    height: 55px;
    min-width: 184px;
}

.btn-primary-et:hover {
    box-shadow: 0 5px 10px #ccc;
}

.btn-secondary-et {
    color: #fff;
    background-color: #2e7d32;
}

    .btn-secondary-et:hover {
        box-shadow: 0 5px 10px #ccc;
        color: #fff;
    }

.btn-outline-et {
    border: 1px solid #2e7d32;
    color: #000;
    background-color: #fff;
}

    .btn-outline-et:hover {
        background-color: #2e7d32;
        color: #fff;
        box-shadow: 0 5px 10px #ccc;
    }

.btn-outline-radius-et {
    border: 1px solid #2e7d32;
    color: #000;
    background-color: #fff;
    border-radius: 25px;
}

    .btn-outline-radius-et:hover {
        background-color: #2e7d32;
        color: #fff;
        box-shadow: 0 5px 10px #ccc;
    }

.btn-radius-et {
    background-color: #2e7d32;
    color: #fff;
    border-radius: 25px;
}

    .btn-radius-et:hover {
        box-shadow: 0 5px 10px #ccc;
        color: #fff;
    }

.btn:focus {
    outline: none;
    box-shadow: none;
}

/* end buttons from main site */
.page-item.active .page-link {
    background-color: #538b3c;
    border-color: #538b3c;
}

.page-link,
.page-link:hover {
    color: #538b3c;
}

    .page-link:focus {
        color: #538b3c;
        box-shadow: none;
    }

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232e7d32'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
}

/* button style */
.duty-free-shop-now-btn {
    background-color: #2e7d32;
    border-color: #2e7d32 !important;
    border-radius: 6px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 50px;
    width: 185px;
    margin-top: 5em;
    margin-left: 6em;
}

    .duty-free-shop-now-btn:hover,
    .duty-free-shop-now-btn:active,
    .duty-free-shop-now-btn:focus:visited {
        background-color: #2e7d32;
        border-color: #2e7d32 !important;
        border-radius: 6px !important;
        color: #fff !important;
        font-size: 16px !important;
        font-weight: bolder !important;
    }

.home-product-list-container {
    background: var(--main-background-color);
}

.product-list-container {
    padding: 5rem 0;
    background: var(--main-background-color);
    padding-top: 2rem;
}

.df-filter-accordion button {
    background: #eee !important;
    color: #000 !important;
}

@media (max-width: 768px) {
    .df-sort-label {
        display: none;
    }

    .filter-btn {
        display: block;
    }
    .duty-free-shop-now-btn{
        margin-left: 0em;
    }
}

@media (min-width: 992px) {
    .filter-btn {
        display: none;
    }
}

.btn.btn-primary-et:hover {
    color: #fff;
    background-color: #005005;
}
