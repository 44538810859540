.duty-free-payment-container {
  text-align: left;
  border: 1px solid #a7a7a775;
  box-shadow: 0px 2px 8px 0px #0000002b;
  border-radius: 6px 6px 17px 17px;
  max-width: 700px;
}

.duty-free-radio-container {
  display: flex;
  user-select: none;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  /* font-size: 2px; */
}

.duty-free-custom-radio-btn {
  display: flex;
  max-width: 100%;
}
.duty-free-custom-radio-insider-container {
  padding: 15px;
  border: 1px solid #ddd;
  font-size: 17px;
  cursor: pointer;
  height: 87px;
  width: 123px;
  border-radius: 6px;
  margin: 5px 15px;
  box-shadow: 0px 2px 6px #dddddd70;
}
.modal-on-boarding .duty-free-custom-radio-insider-container {
  margin: 5px 0px;
}
.duty-free-custom-radio-insider-container:hover {
  border: 1px solid #2e7d32;
  box-shadow: 0px 2px 6px #2e7d327d;
}

.duty-free-custom-radio-insider-container.custom-selected-btn {
  border: 1px solid #2e7d32;
  color: #2e7d32;
  /* box-shadow: 0px 2px 6px #2e7d327d; */
}

.card-type-icon {
  height: 45px;
  width: 53px;
  border-radius: 6px;
  border: 1px solid #ddd;
  cursor: pointer;
}
.card-type-container {
  border-radius: 6px;
  padding: 0px 10px;
}
.card-type-icon.selected-card-type {
  border: 1px solid #2e7d32;
  box-shadow: 0px 2px 10px #2e7d3247;
}

@media (max-width: 359px) {
  .duty-free-custom-radio-insider-container {
    padding: 16px 12px;
    font-size: 14px;
    height: 66px;
    width: 101px;
  }
  .duty-free-custom-radio-insider-container svg {
    font-size: 1.3em !important;
  }
}

@media (max-width: 425px) {
  .duty-free-custom-radio-insider-container {
    padding: 16px 12px;
    font-size: 14px;
    height: 66px;
    width: 101px;
  }
  .duty-free-custom-radio-insider-container svg {
    font-size: 1.3em !important;
  }
}
.duty-free-custom-radio-btn-insider-flight {
  width: 100%;
  height: auto;
  margin-bottom: 16px;
  padding: 15px 0px;
}
.duty-free-custom-radio-btn-insider-flight .flight-airport-container {
  font-size: 14px;
  font-weight: 500;
}
.duty-free-custom-radio-btn-insider-flight .flight-number-container span {
  font-size: 11px;
  background: #2e7d32;
  color: white;
  border-radius: 15px;
  height: 1.6em;
  width: 4.6em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.flight-number-container {
  margin-bottom: 3px;
}
.duty-free-custom-radio-btn-insider-flight .departure-date span {
  display: flex;
  font-size: 14px;
  font-weight: 500;
}
.duty-free-custom-radio-btn-flight{
  width: 100%;
}