.skeleton-box {
  width: 300px;
  height: 200px;
  margin-bottom: 30px;
  /* -webkit-mask:
          linear-gradient(#000 0 0) top /100% 70%,
          linear-gradient(#000 0 0) bottom right/70% 20%,
          radial-gradient(circle closest-side, #000 90%, #0000) bottom left/25% 25%;
      -webkit-mask-repeat: no-repeat; */
  /* background: linear-gradient(90deg, #ccca 40%, #fffa, #ccca 60%) right/300% 100%; */
  animation: m 1s linear infinite;
}
.skeleton {
  animation: m 2s linear infinite alternate;
}
.skeleton-img {
  width: 210px;
  height: 302px;
  margin-bottom: 10px;
}

.skeleton-title {
  width: 210px;
  height: 30px;
  margin-bottom: 10px;
  border-radius: 0.125rem;
}

.skeleton-price {
  width: 210px;
  height: 20px;
  margin-bottom: 10px;
  border-radius: 0.125rem;
}

.skeleton-reward {
  width: 210px;
  height: 15px;
  margin-bottom: 10px;
  border-radius: 0.125rem;
}
.skeleton-product-img {
  width: 150px;
  background-size: contain;
  margin: 0 auto;
  height: 200px;
  border-radius: 5px 5px 0px 0px;
}
.skeleton-on-product-price{
  display: inline-flex;
  width: 44px;
  height: 23px;
  margin: 0px !important;
}
.skeleton-on-product-price.mile-continaer{
  margin-left: 12px !important;
}
.skeleton-text-placeholder {
  color: #d1dbe0 !important;
}
.skeleton-main-image {
    width: 100%;
    height: 256px;
  }
@media(min-width: 575px) {
    .skeleton-main-image {
        height: 365px;
      }
}
@media(min-width: 768px) {
    .skeleton-main-image {
        height: 600px;
      }
}
@keyframes m {
  /* to {
          background-position: left;
      } */
  0% {
    background-color: #a3b8c2;
  }

  100% {
    background-color: #f0f3f5;
  }
}
@keyframes n {
  /* to {
          background-position: left;
      } */
  0% {
    background-color: #f0f3f5;
  }

  100% {
    background-color: #a3b8c2;
  }
}
