.duty-free-card-price {
  font-weight: 600;
  font-size: 1.5rem;
}
.duty-free-card-currency {
  font-size: 14px;
  font-weight: 600;
  color: #6d6d6d;
}

.duty-free-card-old-price {
  color: #6d6d6d;
  font-size: 14px;
  font-weight: 600;
  text-decoration: line-through;
  text-decoration-color: #6d6d6d;
  text-decoration-thickness: 0.1em;
  margin-left: 12px;
}
.df-card-img {
  width: 150px;
  height: 150px;
  background-size: contain;
  margin: 0px auto;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
}
.duty-free-card {
  /* box-shadow: 0px 2px 7px 0px #a5a5a552; */
  border: 1px solid #00000014;
  color: black !important;
  cursor: pointer;
  text-decoration: none;
  background: #fff;
  height: 94%;
  border-radius: 5px;
  padding-top: 5rem;
  position: relative;
}
.duty-free-card:hover {
  box-shadow: 0 5px 10px #ddd;
}
.duty-free-card:hover {
  border: 1px solid #00000021;
  text-decoration: none !important;
}
.duty-free-text-badge {
	background-color: #649539;
	color: #ffffff;
  padding: 7px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.d-pos{
  position: absolute;
  top:0.5rem;
  width: 100%;
}
.duty-free-card-title{
  font-size: 1rem;
}
.duty-free-new-badge {
  background-color: #649539;
  top: 10px;
  right: 13px;
}
.custom-card{
  box-shadow: 0px 2px 7px 0px #a5a5a58f;
  border: 1px solid #00000021;
  background: white;
  border-radius: 3px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9f9;
}
.duty-free-badge-container{
  flex-wrap: wrap;
}
.duty-free-card-badge {
  z-index: 1;
  font-size: 12px;
  font-weight: 600;
}

.arrow-right {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid white;
  bottom: 0;
  left: -1px;
}
.duty-free-card-footer-text {
  font-weight: 700;
}
.duty-free-card-footer {
  background-color: #ffbe00;
  padding: 7px 0px;
  border-radius: 0px 0px 15px 15px;
}
.duty-free-card-detail {
  height: 48px;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
}
.duty-free-card-empty-footer {
  height: 38px;
}
.duty-free-card-image {
  /* padding: 0 4.6em !important; */
  min-height: 142px;
}
.duty-free-arrow-icon {
  font-size: 17px;
}
span.duty-free-arrow-icon-container {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 2px 6px #0000006e;
  cursor: pointer;
  border: 1px solid #0000001a;
  opacity: 0.7;
  position: absolute;
  z-index: 2;
  top: 35%;
}
span.duty-free-arrow-icon-container:hover {
  opacity: 1;
}

span.duty-free-arrow-icon-container[disabled]{
  background: #d6d6d6 !important;
  opacity: 0.7 !important;
}

.duty-free-arrow-icon-right {
  right: 0;
}
.duty-free-arrow-icon-left {
  left: 0;
}

/* banner related */
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z'/%3E%3C/svg%3E");
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z'/%3E%3C/svg%3E");
}
.carousel-indicators [data-bs-target]{
  background-color: #7e7e7e;
}
.new-arrival-badge-text{
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer-badge-text{
  display: flex;
  align-items: center;
  justify-content: center;
}
.deals-container{
  font-size: 14px;
    font-weight: 600;
    margin-top: 6px;
}