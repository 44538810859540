/* .secondary-header.container {
  padding-left: 15%;
} */
@media (max-width: 991px) {
  .secondary-header.container {
    padding-left: 0;
  }

  .logo-container-header.et-container.container {
    max-width: 100%;
    margin-inline: 0.5rem;
  }

  .top-nav-link {
    font-weight: 600;
    flex-direction: row;
    padding-right: 20px;
  }

  .top-nav-link a {
    padding-right: 20px;
  }

  .auto-suggest-container {
    width: 100% !important;
    top: 2px !important;
  }

  .carousel-caption {
    margin-bottom: 110px !important;
  }
}

.duty-free-select-input {
  padding: 10px 29px;
  font-weight: 600;
  border-radius: 6px 0px 0px 6px !important;
  border-right: none !important;
}

.duty-free-select-input:focus {
  border: 1px solid #2e7d32 !important;
  box-shadow: -2px 0 0px 0.1rem rgb(96 173 94 / 52%) !important;
}

.secondary-header.container .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.76);
  font-weight: 600;
  font-size: 15px;
  padding: 10px 6px;
  padding-bottom: 0px;
}

.secondary-header.container .navbar-nav .nav-link:hover {
  color: #2e7d32;
}

.header-search-nav.navbar-nav {
  width: 60%;
  margin-top: 20px;
}

.fade.search-modal.modal.show {
  background: #272727d9;
  padding-top: 165px;
}

.header-search-form button {
  /* background-color: #2e7d32; */
  border-color: #0000003b;
  outline: 0;
  padding: 10px 15px;
  border-radius: 0px 7px 7px 0px !important;
}

.header-search-form button:hover,
.header-search-form button:active,
.header-search-form button :focus:visited {
  /* background-color: #005005 !important; */
  /* border-color: #005005 !important; */
  color: white;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.header-search-form button:focus,
.header-search-form button:active {
  outline: 0;
  /* background-color: #005005 !important; */
  /* box-shadow: 0 0 0px 0.14rem #2f7f3338 !important; */
}

.search-modal .modal-content {
  background-color: #00000000 !important;
}

.header-search-form input {
  padding: 22px 15px;
  /* border-top-left-radius: 5px !important; */
  /* border-bottom-left-radius: 5px !important; */
  border: 1px solid #0000003b;
  font-weight: 600;
}

.header-search-form input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 600;
}

.header-search-form button:hover,
.header-search-form button:focus,
.header-search-form button:active {
  /* background-color: #2e7d32; */
  /* border-color: #2e7d32; */
  outline: 0;
}

.top-nav-link {
  font-weight: 600;
}

.sm-nav-container {
  display: none !important;
}

.header-container-shadow {
  border-bottom: 1px solid #88888852;
  box-shadow: 0px 2px 4px #0000001a;
  position: sticky;
  top: 0;
  background: white;
  z-index: 12;
}

div.mega-menu.custom-mega-menu-container {
  box-shadow: 0px 3px 9px 0px #4242422e;
  border: 1px solid #ddd;
  border-radius: 0px 0px 8px 8px;
}

.header-search-form input:focus {
  border: 1px solid #2e7d32;
  box-shadow: 1px 0 0px 0.1rem rgb(96 173 94 / 52%) !important;
  border-left: none;
}

.duty-free-slect-on-modal {
  max-width: 20%;
  min-width: 94px;
  padding: 8px 12px;
}

.duty-user-name-container {
  font-size: 13px;
  border: 1px solid white;
}

.drop-down-more-container {
  position: absolute !important;
  left: -38px !important;
}

.drop-down-more-container .dropdown-item:active {
  background-color: #2e7d32;
}

a.product-cat:hover {
  color: #2e7d32 !important;
}

.top-nav-item:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
  margin-top: 0.4em;
}

.top-nav-item:hover:after {
  width: 100%;
  background: #2e7d32;
}

.menu-item-list a {
  font-size: 13px;
}

/* 
@media (min-width: 992px) {
  .header-search-form input:focus {
    border: 1px solid #2e7d32;
    box-shadow: -2px 0 0px 0.1rem rgb(96 173 94 / 52%) !important;
  }
  /* .header-search-form input:focus + button {
    border: 1px solid #2e7d32;
    box-shadow: 0 0 0 0.2rem rgb(96 173 94 / 50%) !important;
  } 
} */

@media (max-width: 991px) {
  .mob-top-nav-link.navbar-nav {
    display: flex;
    flex-direction: revert;
    justify-content: space-between;
    width: 25%;
    margin-left: auto;
  }

  .header-search-form input {
    padding: 24px !important;
  }

  .mob-menu.nav-link {
    font-size: 25px;
    color: #000;
  }

  .sm-nav-container {
    display: flex !important;
  }

  .md-nav-container {
    display: none !important;
  }

  .mob-top-nav-link.navbar-nav .nav-link {
    font-size: 20px;
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 5px 10px #ddd;
  overflow-x: hidden;
  transition: 0.1s;
  padding-top: 60px;
}

.sidenav a {
  padding: 17px 8px 17px 32px;
  text-decoration: none;
  font-size: 16px;
  color: #000000b5;
  text-align: justify;
  font-weight: 600;
  display: block;
  border-bottom: 1px solid #ddd;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 25px;
  color: #0000008c;
  margin-left: 50px;
  border: 0;
}

.sidenav.show {
  width: 50%;
}

.sidenav .accordion-button {
  color: inherit;
}

.mega-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  padding: 0;
  z-index: 1;
  max-width: 1300px;
  min-height: 200px;
}

.brand-mega-menu {
  max-width: 1300px;
  margin-left: -113px;
}

.dropdown:hover .mega-menu {
  display: block;
}

.menu-item-container {
  display: flex;
}

.menu-item-list li {
  border: none;
  cursor: pointer !important;
}

.menu-item-list li.list-group-item.sub-cat {
  color: rgba(0, 0, 0, 0.5);
  padding: 5px;
}

.menu-item-list li.list-group-item.sub-cat:hover {
  color: #2e7d32;
  padding: 5px;
  /* font-size: 18px; */
}

.menu-item-list {
  width: 1300px;
  max-width: 1300px;
  padding: 10px 8px;
  min-height: 450px;
}

@media (max-width: 1400px) {
  .menu-item-list {
    width: 1100px;
    max-width: 1300px;
  }

  .category-image {
    width: 529px !important
  }
}

@media (max-width: 1200px) {
  .menu-item-list {
    width: 965px;
    max-width: 1300px;
  }

  .category-image {
    width: 395px !important;
  }
}

@media (min-width: 1200px) {
  .lang-curency-container {
    left: 40px !important
  }
}

@media (min-width: 1400px) {
  .lang-curency-container {
    left: -26px !important;
  }
}

.menu-item-content {
  width: 50vw;
  left: 228px;
  top: -2px;
  padding: 0;
  display: none;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .mob-top-nav-link.navbar-nav {
    display: flex;
    flex-direction: revert;
    justify-content: space-between;
    width: 29%;
    margin-left: auto;
  }

  .sidenav.show {
    width: 80%;
  }

  .search-modal .modal-dialog {
    width: 90%;
    margin: 0 auto !important;
  }
}

@media (max-width: 575px) {
  .mob-top-nav-link.navbar-nav {
    display: flex;
    flex-direction: row;
    position: fixed;
    justify-content: space-around;
    width: 100%;
    margin-left: -4px;
    bottom: 0;
    box-shadow: 0 -5px 10px #1515151f;
    border-top: 1px solid #ddd;
    background-color: #fff;
    left: 4px;
    z-index: 10;
  }

  .header-search-form input {
    padding: 10px;
  }

  .header-search-nav.navbar-nav {
    width: 60%;
    margin: 0px auto;
    margin-top: 20px;
  }

  .sidenav.show {
    width: 85%;
  }

  .drop-down-more-container {
    top: -61px !important;
  }

  .drop-down-dty-free .dropdown-toggle::after {
    transform: rotate(180deg);
  }
}

.mega-menu.custom-mega-menu-container.dropdown-menu:hover {
  border-bottom-right-radius: 0px;
}

.mega-menu.custom-mega-menu-container.dropdown-menu:hover>div {
  box-shadow: 0px 9px 9px 2px #4242421f;
}

.child-list-group {
  position: absolute;
  left: 275px;
  width: 296px;
  display: none;
  top: -11px;
  border-radius: 0px 0rem .5rem 0px;
  box-shadow: 7px 3px 9px 1px #4242422e;
  border: 1px solid #00000024;
  border-left: none;
  background: #f8f9f9;
  height: 382px;
  z-index: -1;
  min-height: 450px;
}

ul.list-group.child-list-group li.sub-cat {
  background: #f8f9f9;
  padding: 0.5rem 1rem;
}

ul.list-group.child-list-group li.sub-cat:hover {
  padding: 0.5rem 1rem;
}

.list-group:hover+.child-list-group,
.child-list-group:hover,
.list-group:hover+.child-list-group+.child-list-group {
  display: flex;
}

div.parent-col:hover .list-group.parent-ul a {
  color: #2e7d32 !important;
}


div.parent-col:hover .child-list-group {
  display: flex;
}

.list-group span {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1rem;
  transition: transform 0.2s ease-in-out;
  transform: rotate(270deg);
  float: right;
  margin-right: -9px;
}

div.parent-col:hover .list-group.parent-ul span {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232e7d32'%3e%3cpath fill-rule='evenodd' stroke='%232e7d32' stroke-width='1' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  margin-right: -12px;
}

.col-7.list-group-item.sub-cat.category-image-li {
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  border-left: 1px solid #00000012;
}

.view-more-link {
  color: white;
  text-decoration: none;
  font-size: 14px;
  padding: 2px 8px;
  background: #6c757d;
  border-radius: 14px;
  padding-bottom: 4px;
}

.view-more-link:hover {
  color: white;
  text-decoration: none;
}

.popular-brands-container a.col-12 {
  margin-bottom: 1em;
  border: 1px solid white;
  display: flex;
  align-items: center;
}

.popular-brands-container a.col-12:hover {
  border: 1px solid #0000009c;
}

.currency-list-container {
  position: absolute;
  margin-left: -93px;
  min-width: 189px;
  background: white;
  z-index: 6;
  border: 1px solid #0000001f;
  padding: 1em;
  border-radius: 5px;
  box-shadow: 0px 6px 8px 0px #00000036;
  flex-wrap: wrap;
}

.currency-list-container span {
  padding: 8px 9px;
  border: 1px solid white;
  border-radius: 6px;
  margin-right: 3px;
  margin-bottom: 3px;
}

.currency-list-child:hover {
  border: 1px solid #2e7d32;
  border-radius: 6px;
}

.currency-list-container span.active {
  border-color: #2e7d32;
  color: #2e7d32;
}

.lang-list-container {
  position: absolute;
  margin-left: -93px;
  width: 189px;
  background: white;
  z-index: 6;
  border: 1px solid #0000001f;
  padding: 1em;
  border-radius: 5px;
  box-shadow: 0px 6px 8px 0px #00000036;
  flex-wrap: wrap;
  margin-top: 11px;
}

.lang-list-container span {
  padding: 8px 9px;
  border: 1px solid white;
  border-radius: 6px;
  margin-right: 3px;
  margin-bottom: 3px;
  width: 100%;
}

.lang-list-container span:hover {
  border-color: #2e7d32;
}

.lang-list-container span.active {
  border-color: #2e7d32;
  color: #2e7d32;
}

.current-lang {
  font-size: 14px;
  font-weight: bold;
  color: #5b5b5b;
}

.current-lang:after {
  background: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' fill='%2320202A'><path d='M59.863 17.14 64 22.628 32 46.86 0 22.628l4.137-5.488L32 38.248z'/></svg>");
  background-position: 50%;
  background-repeat: no-repeat;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 46px;
  width: 11px;
  height: 11px;
  margin-left: -14px;
}

.top-nav-item.activated {
  background-color: #2e7d32;
  border-radius: 50px;
  padding: 4px !important;
  height: 32px;
  font-size: 14px !important;
  margin-top: 7px;
}

.category-image:hover {
  display: none;
}

.category-image {
  position: absolute;
  top: -331px;
  left: 571px;
  width: 550px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.popular-brand-side-container {
  background-color: #ccc;
}

.search-container {
  width: 40%;
  margin-left: 100px;
}

.header-search-desktop-form input {
  padding: 22px 15px;
  border-radius: 8px !important;
  border: unset;
  background-color: #ececec;
  font-weight: 600;
  width: 16em;
  margin-right: 15px;
  border: 1px solid white;
}

.header-search-desktop-form input:focus {
  border: 1px solid #2e7d32;
  box-shadow: 1px 0 0px 0.1rem rgb(96 173 94 / 52%) !important;
  /* border-left: none; */
}

.header-search-desktop-form svg {
  margin-left: -40px;
}

.icon-on-header-desktop {
  height: 30px !important;
  width: 30px !important;
}

.profile-modal,
.shopping-cart-modal {
  display: none;
  background: white;
  min-width: 270px;
  min-height: 100px;
  z-index: 6;
  position: absolute;
  top: 40px;
  border: 1px solid #0000001f;
  padding: 1em;
  border-radius: 5px;
  box-shadow: 0px 6px 8px 0px #00000036;
  flex-wrap: wrap;
}

.account-icon-container-desktop,
.shopping-cart-icon-container-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-icon-container-desktop:hover .profile-modal {
  display: block;
}

.shopping-cart-icon-container-desktop:hover .shopping-cart-modal {
  display: block;
}

.new-customer-label:hover {
  color: rgba(0, 0, 0, 0.76) !important;
}

.language-img {
  width: 18.5px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 3px;
  align-self: center;
}

#search-form-out-side-handler:first-child {
  width: 100% !important;
}

.carousel-indicators button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
  margin-top: 5px;
  margin-inline: 10px !important;
}

.carousel-indicators {
  bottom: -15px !important;
}

.carousel-indicators .active {
  background-color: #2e7d32 !important;
}

.carousel-caption {
  left: 0% !important;
  bottom: -140px !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none !important;
}

.auto-suggest-container {
  width: 600px;
  max-height: 400px;
  overflow-y: hidden;
  position: absolute;
  background: white;
  top: 63px;
  border: 1px solid #0000001f;
  padding: 1em;
  border-radius: 5px;
  box-shadow: 0px 6px 8px 0px #00000036;
}

.auto-suggest-item {
  border-bottom: 1px solid #ddd !important;
}

.auto-suggest-item:hover span {
  border-bottom: 1px solid #2e7d32;
}

.auto-suggest-item:hover {
  cursor: pointer;
}

.shopping-cart-number-indicatior {
    min-width: 20px;
    background: #005005;
    border-radius: 50%;
    margin-left: -5px;
    margin-top: 14px;
}

.lang-curency-container {
  display: flex;
  position: relative;
  left: 91px;
  justify-content: flex-end;
}

.nav-menu-brand-container {
  width: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
}