@media (max-width: 445px) {
  .account-btn .df-account-btn {
    width: 94%;
    margin: 0.5rem auto;
  }
  .sheba-miles-txt {
    text-align: left;
    padding: 0 1rem;
  }
  .sheba-miles-login input {
    padding: 24px 10px !important;
  }
  .container.shebamiles-login-container {
    margin: 0rem auto !important;
    margin-bottom: 5rem !important;
  }
}
.sheba-miles-login input {
  padding: 25px 10px;
}
.sheba-miles-login input.invalid-input-field {
  border: 1px solid red;
}
.sheba-miles-login input.invalid-input-field::placeholder {
  color: red;
}

.duty-free-input-field-control.invalid-input-field {
  border: 1px solid red;
}
.duty-free-input-field-control.invalid-input-field::placeholder {
  color: red;
}
select.duty-free-input-field-control.invalid-input-field option[value=""],
select.duty-free-input-field-control.invalid-input-field {
  color: red;
}
select.duty-free-input-field-control.invalid-input-field option{
  color: black;
}
.sheba-miles-login input:focus {
  border: 1px solid #2e7d32;
  box-shadow: 0 5px 10px #ddd;
}
.container.shebamiles-login-container {
  margin: 4rem auto;
  margin-bottom: 5rem;
}
.duty-phone-number-input-with-area-code {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left: none !important;
}
.duty-area-code-select {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: none !important;
}
a.forgot-password-link {
  color: #6c757d;
  text-decoration: none;
}
a.forgot-password-link:hover {
  color: green;
  text-decoration: underline;
}
.dy-side-menu-item {
  padding: 0.4em 1em;
  margin-bottom: 0.8em;
  border: 1px solid #c1c0c0;
  border-radius: 8px;
  cursor: pointer;
  max-width: 12em;
}
div.dy-side-menu-item:hover {
  background-color: #e9e9e9;
}
div.dy-side-menu-item.active-menu {
  background-color: #e9e9e9;
}
div.dy-side-menu-item span {
  color: #4c4c4c;
  font-weight: 600;
  font-size: 15px;
}
.preoder-action-icon:hover{
  color: #2e7d32;
}
