.contact-us-number {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.contact-us-number:hover {
  color: green;
}
.popular-brands-container a.col-6:hover {
  border: 1px solid #b5b5b5;
  text-decoration: none;
  box-shadow: 0 0 8px 3px #ccc;
}
.popular-brands-container a.col-6 {
  border: 1px solid #ccc;
  display: flex;
  text-decoration: none;
  align-items: center;
  min-height:172px;
}

ul.list-with-tick-checkmark  {
  list-style: none;
}

ul.list-with-tick-checkmark li:before {
  content: '✓';
  margin-right: .51em;
}
