.consent-container {
  background-color: #292929e6 !important;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0px 0;
  color: white;
  z-index: 12;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.7);
  text-align: center;
}
.gdpr-consetn-container-p {
  font-size: 12px;
}
.cookie-text-size {
  font-size: 12px;
  color: white;
  text-align: left;
  position: relative;
}
.cookie-btn:hover {
  border: 2px solid white;
  margin: 0px;
}
.cookie-btn:active {
  outline: none;
}
.cookie-btn {
  border-color: #292929;
  padding: 0.4em 1em;
  background: #292929;
  border-radius: 5px;
  border-style: solid;
  border: 1px solid #292929;
  text-align: center;
  margin: 1px;
}
.cookie-btn-active {
  border: 1px solid white;
}

.gdpr-consetn-container-p a {
  color: white;
  text-decoration: underline;
}

.gdpr-consetn-container-p a:hover {
  color: green;
  text-decoration: underline;
}

@media (min-width: 576px) {
  .cookie-text-size {
    font-size: 16px;
  }
}
