.duty-free-product-price {
  font-size: 24px;
  font-weight: 600;
}

.duty-free-product-currency {
  font-size: 24px;
  font-weight: 600;
}

.duty-free-product-title-underline {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.duty-free-quantity-container {
  text-align: center;
  font-size: 20px;
  height: 38px;
  border-right: none;
  border-left: none;
  font-weight: 600;
}

.duty-free-add-to-wishlist {
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #6d6d6d;
}

#img-zoomer-box:hover,
#img-zoomer-box:active {
  cursor: zoom-in;
  display: block;
}

#img-zoomer-box:hover #img-2,
#img-zoomer-box:active #img-2 {
  opacity: 1;
}

.dty-free-toast-container {
  position: fixed;
  top: 77px;
  z-index: 12;
  background: white;
  right: 22px;
  max-width: 269px;
  width: 100%;
}
.video-play-icon {
  background: #649539;
  color: white;
  border-radius: 100%;
  font-size: 40px !important;
  position: relative;
  left: 6%;
  top: -40px;
  padding: 3px;
}
.dty-free-toast-container>div.toast-header {
  border-radius: 0.25rem;
  border-bottom: none;
}

.dty-free-toast-warring-container {
  max-width: 438px;
}

.dty-free-toast-container>div.toast-header button.btn-close:focus {
  box-shadow: 0 0 0px 0.14rem #2f7f3338 !important;
}

#img-2 {
  width: 100%;
  height: 340px;
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
  background-repeat: no-repeat;
  background-color: white;
  pointer-events: none;
  position: absolute;
  opacity: 0;
  z-index: 99;
  border-radius: 6px;
  display: block;
  left: 479px;
  bottom: 215px;
  transition: opacity 0.2s;
  overflow: hidden;
}

.card-type-top-container {
  border: 1px solid #fff;
  box-shadow: 0px 0px 4px 0px #fff;
}

.card-type-warning {
  border: 1px solid #9a0000;
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px #9a00007a;
  transition: border-color 0.15s ease-in;
  animation: pulse 0.5s ease-out;
}

.duty-free-available-image-list {
  cursor: pointer;
  margin-right: 16px;
  padding: 7px;
  width: 80px;
  border: 2px solid #30303070;
  border-radius: 3px;
  background: white;
}

.duty-free-available-image-list img {
  border-radius: 5px;
}

.duty-free-available-image-list.active {
  border: 2px solid #303030ad;
}

.duty-free-product-price-in-miles {
  font-size: 0.9em;
  font-weight: 600;
}

.js-image-zoom__zoomed-image {
  z-index: 1;
  left: calc(77% + 0px) !important;
}

button.plus-quantity-btn,
button.plus-quantity-btn:hover {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

button.minus-quantity-btn,
.minus-quantity-btn:hover {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(154, 0, 0, 0.4);
  }

  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(154, 0, 0, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(154, 0, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(154, 0, 0, 0.4);
  }

  100% {
    -moz-box-shadow: 0 0 0 10px rgba(154, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(154, 0, 0, 0);
  }
}

.js-image-zoom__zoomed-area {
  opacity: 0.6 !important;
  border: 1px solid #00000091;
  cursor: zoom-in;
}


@media (min-width: 992px) {
  .js-image-zoom__zoomed-image {
    left: -136px !important;
  }
}

@media (min-width: 1200px) {
  .js-image-zoom__zoomed-image {
    left: 25px !important;
  }
}

@media (min-width: 1400px) {
  .js-image-zoom__zoomed-image {
    left: -10px !important;
  }
}

.shape {
  border-style: solid;
  border-width: 0 70px 40px 0;
  float: right;
  height: 0px;
  width: 0px;
  border-color: rgba(255, 255, 255, 0) #d9534f rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  -ms-transform: rotate(360deg);
  /* IE 9 */
  -o-transform: rotate(360deg);
  /* Opera 10.5 */
  -webkit-transform: rotate(360deg);
  /* Safari and Chrome */
  transform: rotate(360deg);
}

.listing {
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin: 15px 0;
  overflow: hidden;
}

/* .listing:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: rotate scale(1.1);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
} */
.listing-radius {
  border-radius: 7px;
}

.listing-danger {
  border-color: #d9534f;
}

.listing-danger .shape {
  border-color: transparent #d9533f transparent transparent;
}

.listing-success {
  border-color: #5cb85c;
}

.listing-success .shape {
  border-color: transparent #5cb75c transparent transparent;
}

.listing-default {
  border-color: #999999;
}

.listing-default .shape {
  border-color: transparent #999999 transparent transparent;
}

.listing-primary {
  border-color: #428bca;
}

.listing-primary .shape {
  border-color: transparent #318bca transparent transparent;
}

.listing-info {
  border-color: #5bc0de;
}

.listing-info .shape {
  border-color: transparent #5bc0de transparent transparent;
}

.listing-warning {
  border-color: #f0ad4e;
}

.listing-warning .shape {
  border-color: transparent #f0ad4e transparent transparent;
}

.shape-text {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  right: -40px;
  top: 2px;
  white-space: nowrap;
  -ms-transform: rotate(30deg);
  /* IE 9 */
  -o-transform: rotate(360deg);
  /* Opera 10.5 */
  -webkit-transform: rotate(30deg);
  /* Safari and Chrome */
  transform: rotate(30deg);
}

.listing-content {
  padding: 0 20px 10px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

button.btn-plus-or-minus {
  border: none;
  padding: 0.1em;
  background-color: #ccc !important;
  font-weight: 700;
  color: #000 !important;
}
/* .zoomed-image-container img:first-child, .zoomed-image-container > div > div.js-image-zoom__zoomed-area:first-of-type, .zoomed-image-container > div > div.js-image-zoom__zoomed-image:first-of-type{
  display: none !important;
} */