.promotion-container {
    background-image: url("/public/images/promotion-mobile-size.jpg");
    height: 450px;
    background-repeat: round;
    background-size: cover;
    border-radius: 8px;
}

.promotion-container > div{
  /* background-color: #7d9865a6; */
}

@media (min-width: 760px) {
    .promotion-container {
        background-image: url(https://b2cappdevstorageaccount.blob.core.windows.net/dutyfree/vbwarwgu.dy5.jpeg);
    }
}
@media (min-width: 1200px) {
    .promotion-container {
        background-image: url(https://b2cappdevstorageaccount.blob.core.windows.net/dutyfree/vbwarwgu.dy5.jpeg);
    }
}
