.footer {
  background-color: var(--footer-background-color);
  border-top: 1px solid #0000001a;
}
.footer-sub-container {
  border-bottom: 1px solid #00000030;
  padding-top: 2em;
  padding-bottom: 2em;
}

.social-links-container > .fa-facebook-f {
  color: #3b5999;
}

.social-links-container > .fa-twitter {
  color: #0084ff;
}
.social-links-container > .fa-linkedin-in {
  color: #0077b5;
}
.social-links-container > .fa-youtube {
  color: #cd201f;
}
.social-links-container > .fa-instagram {
  color: #e4405f;
}
.social-links-container > .fa-weibo {
  color: #df2029;
}

.social-links-container.fa-facebook:hover,
.social-links-container > .fa-facebook-f {
  color: #3b5999;
  border-color: #3b5999;
}

.social-links-container.fa-twitter:hover,
.social-links-container:hover > .fa-twitter {
  color: #0084ff;
  border-color: #0084ff;
}
.social-links-container.fa-linkedin:hover,
.social-links-container:hover > .fa-linkedin-in {
  color: #0077b5;
  border-color: #0077b5;
}
.social-links-container.fa-youtube:hover,
.social-links-container:hover > .fa-youtube {
  color: #cd201f;
  border-color: #cd201f;
}
.social-links-container.fa-instagram:hover,
.social-links-container:hover > .fa-instagram {
  color: #e4405f;
  border-color: #e4405f;
}
.social-links-container.fa-weibo:hover,
.social-links-container:hover > .fa-weibo {
  color: #df2029;
  border-color: #df2029;
}

ul.ethiopian-app-container a:hover {
  opacity: 0.7;
}

.social-links-container {
  display: inline-block;
  border: 1px solid #d5dae6;
  border-radius: 50%;
  margin: 3px 7px;
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 8px;
  align-items: center;
  justify-content: center;
}
.social-links-fontawsome {
  font-size: 22px;
  color: #d5dae6;
}

.footer-links-container a {
  color: #484e53;
  text-decoration: none;
}
.footer-links-container li {
  margin: 10px 0;
}
.footer-links-container a:hover {
  color: green;
  text-decoration: underline;
}
@media (max-width: 575.98px) {
  .footer-links-container a {
    font-size: 17px;
  }
  .footer-links-container li {
    padding: 6px;
  }
  .footer-end {
    margin-bottom: 55px;
  }
}
